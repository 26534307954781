<template>
  <div
    v-loading="isFetchingInvoices"
    class="PaymentInvoices"
  >
    <table
      v-if="invoices.length"
      class="table is-fullwidth"
    >
      <thead>
        <tr>
          <th class="is-narrow">
            {{ $t('components.billing.payment_invoices.date') }}
          </th>
          <th class="is-narrow">
            {{ $t('components.billing.payment_invoices.status') }}
          </th>
          <th>{{ $t('components.billing.payment_invoices.plan') }}</th>
          <th class="has-text-right">
            {{ $t('components.billing.payment_invoices.discount') }}
          </th>
          <th class="has-text-right">
            {{ $t('components.billing.payment_invoices.subtotal') }}
          </th>
          <th class="has-text-right">
            {{ $t('components.billing.payment_invoices.total') }}
          </th>
          <!--<th>{{ $t('components.billing.payment_invoices.invoice') }}</th>-->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="invoice in invoices"
          :key="invoice.id"
        >
          <td class="has-nowrap">
            {{ publicationDate(invoice.date) }}
          </td>
          <td>{{ invoice.paid ? $t('components.billing.payment_invoices.paid') : $t('components.billing.payment_invoices.unpaid') }}</td>
          <td class="has-nowrap">
            {{ invoice.plan }} {{ invoice.commitment }}
          </td>
          <td class="has-text-right">
            <base-coupon
              v-if="invoice.coupon"
              :coupon="invoice.coupon"
              :value="invoice.subtotal"
              :fallback-currency="invoice.currency"
            />
          </td>
          <td class="has-text-right">
            {{ formatMoney(invoice.subtotal, false, invoice.currency) }}
          </td>
          <td class="has-text-right">
            {{ formatMoney(invoice.total, false, invoice.currency) }}
          </td>
          <td v-if="invoice.href">
            <a
              :href="invoice.href"
              class="has-text-primary"
            >
              {{ $t('general.view') }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="message"
    >
      <div class="message-body">
        {{ $t('components.billing.payment_invoices.no_invoices') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseCoupon from '@/components/billing/BaseCoupon'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

/**
 * Renders a table of invoices
 * @module PaymentInvoices
 */
export default {
  name: 'PaymentInvoices',
  components: { BaseCoupon },
  mixins: [FormatMoneyMixin, PublicationDateMixin],
  props: {
    organisationId: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState({
      /** @type HF_Invoice[] */
      invoices: state => state.auth.invoices,
      isFetchingInvoices: state => state.auth.isFetchingInvoices
    })
  },

  mounted () {
    if (!this.invoices.length) {
      this.fetchInvoices(this.organisationId)
    }
  },

  methods: {
    ...mapActions(['fetchInvoices'])
  }
}
</script>
