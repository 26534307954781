<template>
  <PageSection
    class="Invoices"
    :page-title="$t('pages.settings.billing.invoices.headline')"
    :page-tooltip="$t('pages.settings.billing.invoices.sidebar_description')"
  >
    <PaymentInvoices ref="invoices" :organisation-id="firstOrganisation.id" />
  </PageSection>
</template>

<script>
import { mapGetters } from 'vuex'

import PageSection from '@/components/core/PageSection.vue'

import PaymentInvoices from '@/components/billing/PaymentInvoices.vue'

export default {
  name: 'Invoices',

  components: {
    PageSection,
    PaymentInvoices
  },

  computed: {
    ...mapGetters(['firstOrganisation'])
  }
}
</script>
