<template>
  <coupon-data-provider v-bind="$attrs">
    <template slot-scope="{ value, currency, isPercentage, discount, id }">
      <el-tooltip :content="id">
        <span>{{ formatMoney(discount, false, currency || fallbackCurrency) }}</span>
        <span v-if="isPercentage">({{ percentageFormat(value) }})</span>
      </el-tooltip>
    </template>
  </coupon-data-provider>
</template>

<script>
import CouponDataProvider from '@/components/billing/CouponDataProvider'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'

/**
 * @module BaseCoupon
 */
export default {
  name: 'BaseCoupon',

  components: { CouponDataProvider },

  mixins: [FormatMoneyMixin, PercentageMixin],

  inheritAttrs: false,

  props: {
    fallbackCurrency: {
      type: String,
      default: ''
    }
  }
}
</script>
